import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'fieldset']
  static values = {
    option: String
  }

  connect() {
    this.toggle()
  }

  toggle() {
    this.fieldsetTarget.style.display = 'none'

    this.checkboxTargets.forEach((el) => {
      if (el.checked && el.value === this.optionValue) {
        this.fieldsetTarget.style.display = 'grid'
      }
    })
  }
}
